
<template>

  <v-footer class="pa-4" absolute color="#000033" dark fixed>

    &copy; {{ new Date().getFullYear() }} — <strong> WillWayNow.com </strong>

  </v-footer>

</template>


<!-------------------------------------------------------------------------->
<script>

export default {
  name: 'Footer'
}

</script>
