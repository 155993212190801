
<template>

<v-container>

<v-row>
<v-col>
            <v-card max-width="340" class = "mx-2" elevation="2" outlined shaped>

                        <v-card-title> terms & conditions </v-card-title> 

                        <v-card-text>
                                        <p> There are no terms; there are no conditions.   
                                          Parents are partly responsible for birthdates of babies. </p>
                        </v-card-text> 
                        
            </v-card> <br>     


            <v-card max-width="360" class = "mx-2" elevation="2" outlined shaped>

                        <v-card-title> data safety </v-card-title> 

                        <v-card-text>
                                        <p> Since data is only saved _ in the session _  
                                         and No Cookies _ astroSimpatico is GPDR compliant.
                                         </p>
                        </v-card-text> 
                        
            </v-card> <br>    


            <v-card max-width="280" class = "mx-2" elevation="2" outlined shaped>

                        <v-checkbox label = " I agree to terms & conditions" class = "mx-2" ></v-checkbox>

            </v-card> <br>    

            <v-card max-width="220" class = "mx-2" elevation="2" outlined shaped>

                        <v-card-text> &copy; {{ new Date().getFullYear() }} — <strong> WillWayNow LLC </strong> </v-card-text> 
                        
            </v-card>     

</v-col>
</v-row>
<br>

</v-container>





</template>

<!---------------------------------------------->
<script>

import Footer  from '../components/Footer' ;

export default {
    components: { 
        Footer 
        }

}

</script>
